
import { defineComponent } from "vue";

export default defineComponent({
  setup () {
    const types = {
      taxonomies: "button@5",
    };
    return { types };
  },
});
