var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{class:[
    'justify-center',
    'align-center',
    'mt-1', 'py-2',
    _vm.$vuetify.theme.dark ? 'darkBg' : 'white',
    'rounded-lg'
  ]},[_c('v-skeleton-loader',{staticClass:"accentBg",attrs:{"type":"taxonomies","types":_vm.types,"width":"100%"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }